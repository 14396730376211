import {combineReducers} from 'redux';
import { connectRouter } from 'connected-react-router';

import * as COMMON from './types';

import authReducer from '../containers/auth/store/reducers';
import userReducer from '../containers/user/store/reducers';
import clientsReducer from '../containers/clients/store/reducers';
import insightsReducer from '../containers/insights/store/reducers';

const appReducer = (state = {
	status: 'loading', // loading|error|ready|restricted
	statusCode: null, // loading|error|ready|restricted
	languages: [],
	interfaceLang: '',
	config: {},
	texts: {},
	msg: '',
}, action) => {
	switch (action.type) {
		case COMMON.APP_STATUS:
			return {
				...state,
				status: action.payload,
			};
		case COMMON.APP_SET_STATUS_CODE:
			return {
				...state,
				statusCode: action.payload.statusCode,
			};
		case COMMON.APP_SET_CONFIG:
			return {
				...state,
				config: action.payload.config,
				msg: action.payload.msg,
				texts: action.payload.texts,
			};
		case COMMON.APP_SET_TRANSLATIONS:
			return {
				...state,
				interfaceLang: action.payload.interfaceLang,
			};
		default:
			return state;
	}
};

const uiDefaultState = {
	section: 'main',
	subsection: '',
	url: '',
	menu: { content: null, options: {} },
	modal: { content: null, options: {} },
	info: { innerWidth: window.innerWidth, innerHeight: window.innerHeight, wsize: '', isMobile: false },
	footer: { content: null, options: {} },
};


const uiReducer = (state = uiDefaultState, action) => {
	switch (action.type) {
		case COMMON.UI_SECTION_CHANGE: {
			const section = action.payload.section;
			const subsection = action.payload.subsection;

			if (section != state.section || subsection != state.subsection) {
				return { ...state, section, subsection };
			}
			return state;
		}

		case COMMON.UI_URL: {
			const url = action.payload;
			if (action.payload != state.url) {
				return { ...state, url: action.payload };
			}
			return state;
		}

		case COMMON.UI_DEVICE_INFO: {
			return { ...state, info: action.payload };
		}

		case COMMON.UI_PREFERENCE_UPDATE: {
			const preference = { ...state.preference };
			preference[action.payload.type] = action.payload.value;
			return { ...state, preference };
		}

		case COMMON.UI_MENU_TOGGLE: {
			let menu = { ...action.payload };
			if (menu.content != null && state.menu.content != null && menu.options.toggle == true) {
				// Reset the content only the id is different
				if (menu.options.id == state.menu.options.id) {
					menu = { ...action.payload, content: null };
				}
			}
			menu.toJSON = function () {
				return { ...this, content: '[Event]', target: '[Event]' };
			};
			return { ...state, menu };
		}
		case COMMON.UI_MODAL_TOGGLE: {
			let modal = action.payload;
			if (modal.content != null && state.modal.content != null && modal.options.toggle == true) {
				modal = { ...action.payload };
			}
			modal.toJSON = function () {
				return { ...this, content: '[Event]', target: '[Event]' };
			};
			return { ...state, modal };
		}
		case COMMON.UI_FOOTER_TOGGLE: {
			let footer = { ...action.payload };
			if (footer.content != null && state.footer.content != null && footer.options.toggle == true) {
				// Reset the content only the id is different
				if (footer.options.id == state.footer.options.id) {
					footer = { ...action.payload, content: null };
				}
			}
			footer.toJSON = function () {
				return { ...this, content: '[Event]', target: '[Event]' };
			};
			return { ...state, footer };
		}
		default:
			return state;
	}
};

const reducer = (history) => combineReducers({
	app: appReducer,
	ui: uiReducer,
	auth: authReducer,
	user: userReducer,
	clients: clientsReducer,
	router: connectRouter(history),
	insights: insightsReducer,
});

export default reducer;
