
const clientsOrganizations = state => state.clients.organizations;
const clientsOrganization = state => state.clients.organization;
const clientsUpdateOrganization = state => state.clients.organizationUpdate;
const clientsDeleteOrganization = state => state.clients.organizationDelete;
const clientsMsg = state => state.clients.msg;
const clientsUsers = state => state.clients.users;
const clientsCreateUsers = state => state.clients.createUsers;
const clientsTenantUsers = state => state.clients.createTenantUsers;
const clientsUpdateTenantUsers = state => state.clients.updateTenantUsers;
const clientsDeleteTenantUsers = state => state.clients.deleteTenantUsers;
const clientsInviteTenantUsers = state => state.clients.inviteTenantUsers;
const clientsResetTenantUsers = state => state.clients.resetTenantUsers;

export {
  clientsOrganizations,
  clientsOrganization,
  clientsUpdateOrganization,
  clientsDeleteOrganization,
  clientsMsg,
  clientsUsers,
  clientsCreateUsers,
  clientsTenantUsers,
  clientsDeleteTenantUsers,
  clientsInviteTenantUsers,
  clientsUpdateTenantUsers,
  clientsResetTenantUsers,
};
