const LOAD_MSG = 'LOAD_MSG';
const SET_MSG = 'SET_MSG';

const LOAD_ORGANIZATIONS = 'LOAD_ORGANIZATIONS';
const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';

const LOAD_UPDATE_ORGANIZATION = 'LOAD_UPDATE_ORGANIZATION';
const SET_UPDATE_ORGANIZATION = 'SET_UPDATE_ORGANIZATION';

const LOAD_CREATE_ORGANIZATION = 'LOAD_CREATE_ORGANIZATION';
const SET_CREATE_ORGANIZATION = 'SET_CREATE_ORGANIZATION';

const LOAD_DELETE_ORGANIZATION = 'LOAD_DELETE_ORGANIZATION';
const SET_DELETE_ORGANIZATION = 'SET_DELETE_ORGANIZATION';
const LOAD_RESET_DELETE_ORGANIZATION = 'LOAD_RESET_DELETE_ORGANIZATION'

const LOAD_RESET_ORGANIZATION = 'LOAD_RESET_ORGANIZATION';

const LOAD_ORGANIZATION = 'LOAD_ORGANIZATION';
const SET_ORGANIZATION = 'SET_ORGANIZATION';

const LOAD_USERS = 'LOAD_USERS';
const SET_USERS = 'SET_USERS';

const LOAD_CREATE_USERS = 'LOAD_CREATE_USERS';
const SET_CREATE_USERS = 'SET_CREATE_USERS';

const LOAD_TENANT_USERS = 'LOAD_TENANT_USERS';
const SET_TENANT_USERS = 'SET_TENANT_USERS';

const LOAD_UPDATE_TENANT_USERS = 'LOAD_UPDATE_TENANT_USERS';
const SET_UPDATE_TENANT_USERS = 'SET_UPDATE_TENANT_USERS';

const LOAD_DELETE_TENANT_USERS = 'LOAD_DELETE_TENANT_USERS';
const SET_DELETE_TENANT_USERS = 'SET_DELETE_TENANT_USERS';

const LOAD_INVITE_TENANT_USERS = 'LOAD_INVITE_TENANT_USERS';
const SET_INVITE_TENANT_USERS = 'SET_INVITE_TENANT_USERS';

const LOAD_RESET_TENANT_USERS = 'LOAD_RESET_TENANT_USERS';
const SET_RESET_TENANT_USERS = 'SET_RESET_TENANT_USERS';

const LOAD_RESET_USERS = 'LOAD_RESET_USERS';
const LOAD_RESET_MSG = 'LOAD_RESET_MSG';

const LOAD_STATS_REPORT_SERIES = 'LOAD_STATS_REPORT_SERIES';
const SET_STATS_REPORT_SERIES = 'SET_STATS_REPORT_SERIES';
const LOAD_RESET_STATS_REPORT_SERIES = 'LOAD_RESET_STATS_REPORT_SERIES';

export {
  LOAD_MSG,
  SET_MSG,
  LOAD_ORGANIZATIONS,
  SET_ORGANIZATIONS,
  LOAD_UPDATE_ORGANIZATION,
  SET_UPDATE_ORGANIZATION,
  LOAD_CREATE_ORGANIZATION,
  SET_CREATE_ORGANIZATION,
  LOAD_DELETE_ORGANIZATION,
  SET_DELETE_ORGANIZATION,
  LOAD_RESET_DELETE_ORGANIZATION,
  LOAD_RESET_ORGANIZATION,
  LOAD_ORGANIZATION,
  SET_ORGANIZATION,
  LOAD_USERS,
  SET_USERS,
  LOAD_CREATE_USERS,
  SET_CREATE_USERS,
  LOAD_TENANT_USERS,
  SET_TENANT_USERS,
  LOAD_UPDATE_TENANT_USERS,
  SET_UPDATE_TENANT_USERS,
  LOAD_DELETE_TENANT_USERS,
  SET_DELETE_TENANT_USERS,
  LOAD_INVITE_TENANT_USERS,
  SET_INVITE_TENANT_USERS,
  LOAD_RESET_TENANT_USERS,
  SET_RESET_TENANT_USERS,
  LOAD_RESET_USERS,
  LOAD_RESET_MSG,
  LOAD_STATS_REPORT_SERIES,
  SET_STATS_REPORT_SERIES,
  LOAD_RESET_STATS_REPORT_SERIES,
};
