import * as USER from './types';
import {getValue} from 'AppUtils/objects';

const defaultState = {
  section: 'user',
  msg: null,
  statusCode: null,
  info: null,
  updateInfo: null,
  organizations: '',
  roles: '',
  profileUpdate: {
    data: '',
    isLoading: false,
  },
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case USER.USER_SET_INFO: {
      const {info, msg, statusCode} = action.payload;

      return {
        ...state,
        info,
        roles: getValue(info, 'roles', []),
        globalPermissions: getValue(info, 'globalPermissions', []),
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_UPDATE_INFO: {
      return {
        ...state,
        msg: '',
        statusCode: '',
      };
    }
    case USER.USER_SET_UPDATE_INFO: {
      const {updateInfo, msg, statusCode} = action.payload;

      return {
        ...state,
        updateInfo,
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_NOTIFICATIONS: {
      const {list, msg, statusCode} = action.payload;

      return {
        ...state,
        notifications: {
          list,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_NOTIFICATIONS_CONSUME:
      return {
        ...state,
        notificationsConsume: {
          ...state.notificationsConsume,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_NOTIFICATIONS_CONSUME: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        notificationsConsume: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_UPDATE_PROFILE:
      return {
        ...state,
        profileUpdate: {
          ...state.profileUpdate,
          isLoading: true,
        },
        msg: '',
        statusCode: '',
      };
    case USER.USER_SET_UPDATE_PROFILE: {
      const {data, msg, statusCode} = action.payload;

      return {
        ...state,
        profileUpdate: {
          data,
          isLoading: false,
        },
        msg,
        statusCode,
      };
    }
    case USER.USER_LOAD_RESET_MSG:
      return {
        ...state,
        msg: '',
        statusCode: '',
      };
    default:
      return state;
  }
}
