import Observable from 'Observable';
import {fork, put, select, take, takeEvery} from 'redux-saga/effects';
import {getValue} from 'AppUtils/objects';
import {apiGet, apiPost, apiPut} from "AppUtils/api";

import * as USER from './types';
import * as AUTH from '../../auth/store/types';

import {loginSessionId, loginXsrfToken} from "../../auth/store/selectors";
import {userInfo} from "./selectors";

function* onLoadUserInfo(action) {
  const xsrfToken = getValue(action, 'payload.xsrfToken', '') ? action.payload.xsrfToken : yield select(loginXsrfToken);
  const sessionId = getValue(action, 'payload.sessionId', '') ? action.payload.sessionId : yield select(loginSessionId);

  const retry = 3;
  let info = '';
  let statusCode = '';
  let msg = '';

  yield apiGet(`/user`)
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && statusCode === 200) {
        info = response.data;
      } else {
        msg = 'Error';
      }
    });

  if (statusCode === 200 && info) {
    yield put({
      type: AUTH.SET_LOGIN,
      payload: {
        sessionId: sessionId,
        xsrfToken: xsrfToken,
        authenticated: true,
        statusCode: statusCode,
      }
    });

    yield put({
      type: USER.USER_SET_INFO,
      payload: {info, statusCode},
    });
  }
}

function* onLoadUpdateUserInfo(action) {
  const settings = getValue(action, 'payload.settings', '');

  const retry = 3;
  let updateInfo = '';
  let statusCode = '';
  let msg = '';

  const response = yield apiPut(`/user`, {settings})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise();

  if (response && !response.message) {
    updateInfo = response;
  } else {
    msg = response.message;
  }

  if (updateInfo) {
    yield put({type: USER.USER_SET_INFO, payload: {info: updateInfo, statusCode}});
  }

  yield put({type: USER.USER_SET_UPDATE_INFO, payload: {updateInfo: msg ? '' : updateInfo, msg, statusCode}});
}

function* onLoadNotifications() {
  const retry = 3;
  let list = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiGet(`/user/notifications`)
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && !response.message) {
        list = response.items;
      } else {
        msg = response.message;
      }
    });

  yield put({type: USER.USER_SET_NOTIFICATIONS, payload: {list, msg, statusCode}});
}

function* onLoadNotificationsConsume(action) {
  const id = getValue(action, 'payload.notificationId', '');
  const callback = getValue(action, 'payload.callback', '') ? action.payload.callback : '';

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiPost(`/user/notifications/consume`, {id})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && !response.message) {
        data = response;
      } else {
        msg = response.message;
      }
    });

  if (!msg) {
    yield put({type: USER.USER_LOAD_NOTIFICATIONS});
    if (callback) {
      yield take(USER.USER_SET_NOTIFICATIONS);
      callback();
    }
  }

  yield put({type: USER.USER_SET_NOTIFICATIONS_CONSUME, payload: {data, msg, statusCode}});
}

function* onLoadProfile(action) {
  const nickname = getValue(action, 'payload.nickname', '');
  const avatarUrl = getValue(action, 'payload.avatarUrl', '');
  const avatarFile = getValue(action, 'payload.avatarFile', '');

  const retry = 3;
  let data = '';
  let msg = '';
  let statusCode = '';

  const response = yield apiPost(`/user/profile`, {nickname, avatarUrl, avatarFile})
    .retryWhen(errors => errors.delay(1000).take(retry))
    .catch(e => Observable.of([]))
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    }).toPromise().then(function (response) {
      if (response && !response.message) {
        data = response;
      } else {
        msg = response.message;
      }
    });

  yield put({type: USER.USER_SET_UPDATE_PROFILE, payload: {data, msg, statusCode}});
}


function* parseTenantId(tenantId) {
  const info = yield select(userInfo);
  const url = new URL(window.location);
  const str = url.pathname;
  const res = str.match(/\/clients\/([0-9]*)\//);
  const clientId = res && res[1] ? parseInt(res[1]) : '';
  let selectedTenantId = tenantId || clientId;

  if (!selectedTenantId) {
    selectedTenantId = getValue(info, 'organizations.0.id');
  }

  return selectedTenantId;
}

function getProvider(platform) {
  platform = platform.toLowerCase();
  let provider = '';

  switch (platform) {
    case 'android':
      provider = 'google';
      break;
    case 'ios':
      provider = 'appstore';
      break;
  }

  return provider;
}

function* userWatchInitialize() {
  yield takeEvery(USER.USER_LOAD_UPDATE_INFO, onLoadUpdateUserInfo);
  yield takeEvery(USER.USER_LOAD_INFO, onLoadUserInfo);
  yield takeEvery(USER.USER_LOAD_NOTIFICATIONS, onLoadNotifications);
  yield takeEvery(USER.USER_LOAD_NOTIFICATIONS_CONSUME, onLoadNotificationsConsume);
  yield takeEvery(USER.USER_LOAD_UPDATE_PROFILE, onLoadProfile);
}


export default function* sagas() {
  yield fork(userWatchInitialize);
}
