import React, {useEffect, useState} from 'react';
import {Breadcrumbs, Button, SvgIcon, Link, Box, LinearProgress} from '@mui/material';
import connect from 'react-redux/es/connect/connect';

import Page from '../../../components/Page';
import Results from '../subcomponents/Results';
import {clientsLoadOrganizations, clientsLoadResetOrganization} from "../store/actions";
import {clientsOrganizations} from "../store/selectors";
import {userInfo} from "../../user/store/selectors";
import {getValue} from "AppUtils/objects";
import {PlusCircle as PlusCircleIcon} from "react-feather";
import {Link as RouterLink} from "react-router-dom";

const stateToProps = state => ({
  clientsOrganizations: clientsOrganizations(state),
  userInfo: userInfo(state),
});

const actionsToProps = dispatch => ({
  clientsLoadOrganizations: (payload) => dispatch(clientsLoadOrganizations(payload)),
  clientsLoadResetOrganization: (payload) => dispatch(clientsLoadResetOrganization(payload)),
});

const Clients = (props) => {
  const info = props.userInfo;
  const globalPermissions = getValue(info, 'globalPermissions', []);
  const organizations = getValue(info, 'organizations', []);

  const [tenants, setTenants] = useState(props.clientsOrganizations);

  useEffect(() => {
    setTenants(props.clientsOrganizations);
  }, [props.clientsOrganizations])

  useEffect(() => {
      props.clientsLoadResetOrganization();
  }, []);

  const breadCrumbs =
    <Breadcrumbs aria-label="breadcrumb">
      <Link variant="body1" to="#" component={RouterLink}>
        Keyboard Management
      </Link>

      <Link variant="body1" to="#" component={RouterLink}>
        Clients
      </Link>
    </Breadcrumbs>;

  const callToAction =
    <RouterLink to="/client-add">
      <Button variant="contained" startIcon={
        <SvgIcon fontSize="small">
          <PlusCircleIcon/>
        </SvgIcon>
      }>
        New Client
      </Button>
    </RouterLink>;

  return (
    <Page title="Clients" breadCrumbs={breadCrumbs} callToAction={globalPermissions.includes('tenant.create') ? callToAction : null}>
        {tenants.isLoading && (
            <Box sx={{ mt: 12 }}>
                <LinearProgress sx={{ borderRadius: 5, height: '7px' }} />
            </Box>
        )}

        {!tenants.isLoading && (
            <Results clients={tenants.data ? tenants.data.data : []}
                     organizations={organizations}
                     clientsLoadOrganizations={props.clientsLoadOrganizations}/>
        )}
    </Page>
  );
};

export default connect(stateToProps, actionsToProps)(Clients);
