const LOAD_MSG = 'LOAD_MSG';
const SET_MSG = 'SET_MSG';

const USER_LOAD_INFO = 'USER_LOAD_INFO';
const USER_SET_INFO = 'USER_SET_INFO';

const USER_LOAD_UPDATE_INFO = 'USER_LOAD_UPDATE_INFO';
const USER_SET_UPDATE_INFO = 'USER_SET_UPDATE_INFO';

const USER_LOAD_NOTIFICATIONS = 'USER_LOAD_NOTIFICATIONS';
const USER_SET_NOTIFICATIONS = 'USER_SET_NOTIFICATIONS';

const USER_LOAD_NOTIFICATIONS_CONSUME = 'USER_LOAD_NOTIFICATIONS_CONSUME';
const USER_SET_NOTIFICATIONS_CONSUME = 'USER_SET_NOTIFICATIONS_CONSUME';

const USER_LOAD_UPDATE_PROFILE = 'USER_LOAD_UPDATE_PROFILE';
const USER_SET_UPDATE_PROFILE = 'USER_SET_UPDATE_PROFILE';

const USER_LOAD_CHANNELS = 'USER_LOAD_CHANNELS';
const USER_SET_CHANNELS = 'USER_SET_CHANNELS';
const USER_LOAD_RESET_MSG = 'USER_LOAD_RESET_MSG';

export {
  LOAD_MSG,
  SET_MSG,
  USER_LOAD_INFO,
  USER_SET_INFO,
  USER_LOAD_UPDATE_INFO,
  USER_SET_UPDATE_INFO,
  USER_LOAD_NOTIFICATIONS,
  USER_SET_NOTIFICATIONS,
  USER_LOAD_NOTIFICATIONS_CONSUME,
  USER_SET_NOTIFICATIONS_CONSUME,
  USER_LOAD_UPDATE_PROFILE,
  USER_SET_UPDATE_PROFILE,
  USER_LOAD_CHANNELS,
  USER_SET_CHANNELS,
  USER_LOAD_RESET_MSG,
};
