import {createAction} from 'redux-actions';

import * as USER from './types';

const loadMsg = createAction(USER.LOAD_MSG);
const setMsg = createAction(USER.SET_MSG);
const userLoadInfo = createAction(USER.USER_LOAD_INFO);
const userLoadUpdateInfo = createAction(USER.USER_LOAD_UPDATE_INFO);
const userLoadNotifications = createAction(USER.USER_LOAD_NOTIFICATIONS);
const userLoadNotificationsConsume = createAction(USER.USER_LOAD_NOTIFICATIONS_CONSUME, data => data);
const userLoadUpdateProfile = createAction(USER.USER_LOAD_UPDATE_PROFILE, data => data);

const userLoadResetMsg = createAction(USER.USER_LOAD_RESET_MSG);

export {
  loadMsg,
  setMsg,
  userLoadInfo,
  userLoadUpdateInfo,
  userLoadNotifications,
  userLoadNotificationsConsume,
  userLoadUpdateProfile,
  userLoadResetMsg,
}
