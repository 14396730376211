import {createStyles, makeStyles} from "@mui/styles";
const useStyles = makeStyles((theme) => createStyles({
    '@global': {
        '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
        },
        html: {
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            minHeight: '100vh',
            width: '100%'
        },
        body: {
            minHeight: '100vh',
            width: '100%',
            backgroundColor: theme.palette.background.dark,
        },
        '#root': {
            minHeight: '100vh',
            width: '100%'
        }
    }
}));

const GlobalStyles = () => {
    useStyles();

    return null;
};

export default GlobalStyles;