const userMsg = state => state.user.msg;
const userStatusCode = state => state.user.statusCode;
const userInfo = state => state.user.info;
const userPermissions = state => {
  let currentOrganization = state.user.info.organizations.filter(
    (org) => org.id === (state.clients.organization.data !== undefined ? state.clients.organization.data.id : 0)
  )[0];

  if( currentOrganization !== undefined ) {
    return currentOrganization.userPermissions;
  }

  return state.user.info.globalPermissions;
};
const userUpdateInfo = state => state.user.updateInfo;
const userNotifications = state => state.user.notifications;
const userNotificationsConsume = state => state.user.notificationsConsume;
const userProfileUpdate = state => state.user.profileUpdate;
const userOrganizations = state => state.user.organizations;
const userRoles = state => state.user.roles;

export {
  userMsg,
  userStatusCode,
  userInfo,
  userPermissions,
  userUpdateInfo,
  userNotifications,
  userNotificationsConsume,
  userProfileUpdate,
  userOrganizations,
  userRoles,
};
