/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
	Box,
	Divider,
	Drawer,
	List,
	ListSubheader,
	SvgIcon,
	Button,
} from '@mui/material';
import {
	ArrowLeft as ArrowLeftIcon,
} from 'react-feather';

import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';

import Logo from '../../../components/vectors/Logo';
import NavBarItem from "../subcomponents/navbar-item/NavBarItem";

import {userInfo, userPermissions} from '../../../containers/user/store/selectors';
import {DataThresholding, Group, Insights} from "@mui/icons-material";

function renderNavItems({items, pathname, depth = 0}) {
	return (
	  <List disablePadding>
			{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}
	  </List>
	);
}

function reduceChildRoutes({acc, pathname, item, depth}) {
	const key = item.title + depth;

	if (item.items) {
		const open = matchPath(pathname, {
			path: item.href,
			exact: false
		});

		if(!item.exclude) {
			acc.push(
				<NavBarItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
					{renderNavItems({
						depth: depth + 1,
						pathname,
						items: item.items
					})}
				</NavBarItem>
			);
		}
	} else {
		if(!item.exclude) {
			acc.push(
				<NavBarItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} />
			);
		}
	}

	return acc;
}

const NavBar = ({ onMobileClose, openMobile, shouldHideNav, organizations, userPermissions, roles, clientId }) => {
	const location = useLocation();


	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
	}, [location.pathname]);

	const sections = [
		{
			title: 'management',
			subheader: 'Management',
			items: [
				{
					title: 'All Partners',
					icon: PeopleAltOutlinedIcon,
					href: `/clients`,
					exclude: !!clientId || organizations.length === 0,
				},
				{
					title: 'Settings',
					icon: DeveloperBoardIcon,
					href: `/clients/${clientId}/settings`,
					exclude: !clientId || (!userPermissions.includes('tenant.view')
																|| !userPermissions.includes('tenant.update')),
				},
			],
		},
		{
			title: 'tappa_insights',
			subheader: 'Reporting',
			items: [
				{
					title: 'Daily Dashboard',
					icon: DataThresholding,
					href: `/clients/${clientId}/daily-dashboard`,
					exclude: !clientId || !userPermissions.includes('dailyDashboard.view'),
				},
				{
					title: 'Retention Dashboard',
					icon: MultilineChartIcon,
					href: `/clients/${clientId}/retention-dashboard`,
					exclude: !clientId || !userPermissions.includes('retentionDashboard.view'),
				},
				{
					title: 'Insights',
					icon: Insights,
					href: `/clients/${clientId}/insights`,
					exclude: !clientId || !userPermissions.includes('insightsDashboard.view'),
				},
				{
					title: 'Audience Management',
					icon: Group,
					href: `/clients/${clientId}/audiences`,
					exclude: !clientId || (!userPermissions.includes('insightsDashboard.view')),
				},
			]
		}
	];

	const content = (
	  <Box height="100%" display="flex" flexDirection="column">
		  <PerfectScrollbar options={{ suppressScrollX: true }}>
				<Box sx={{ display: { xs: 'flex', lg: 'none' }, pt: 6, pb: 4 }} justifyContent="center">
					<RouterLink sx={{ display: 'flex', alignItems: 'center' }} to={!!clientId ? `/clients/${clientId}` : '/clients'}>
						<Logo />
					</RouterLink>
				</Box>

			  <Box sx={{ mx: 4, mt: 16}}>
				  {sections.map((section, index) => {
						if( section.items.filter(item => !item.exclude).length > 0 ) {
							return (
								<List key={'rootNavItem_' + section.title}
											subheader={(<ListSubheader sx={{ textTransform: 'uppercase', color: 'tertiary.main', fontWeight: '800' }} disableSticky>{section.subheader}</ListSubheader>)}>
									{renderNavItems({
										items: section.items,
										pathname: location.pathname
									})}
								</List>);
						}
						else {
							return '';
						}
				  })}
			  </Box>


			  {clientId && (roles.length > 0 || organizations.length > 1) &&
			  <Box sx={{ position: 'absolute', bottom: 0, p: 4, width: '100%', display: 'flex', justifyContent: 'center' }}>
				  <List subheader={(<ListSubheader disableGutters disableSticky>
															<RouterLink to={`/clients`}>
																<Button variant="outlined" color="primary" type="submit">
																	<SvgIcon fontSize="small">
																		<ArrowLeftIcon/>
																	</SvgIcon>
																	<Box sx={{ ml: 4 }}>All Partners</Box>
																</Button>
														</RouterLink>
													</ListSubheader>)}>
					</List>
			  </Box>
			  }
		  </PerfectScrollbar>
	  </Box>
	);


	if( shouldHideNav ) {
		return null;
	}

	return (
	  <>
			<Drawer anchor="left" sx={{ display: {lg: 'none'} }}  PaperProps={{ sx: { width: 288 } }} onClose={onMobileClose} open={openMobile} variant="temporary">
				{content}
			</Drawer>

			<Drawer anchor="left"  sx={{ display: { xs: 'none', lg: 'block' } }} PaperProps={{ sx: { width: 288, top: '65px', height: 'calc(100% - 64px)' } }} open variant="persistent">
				{content}
			</Drawer>
	  </>
	);
};

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool
};

export default NavBar;
