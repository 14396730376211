import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import NavBar from "../navbar/main/NavBar";
import TopBar from '../topbar/TopBar';
import {Box} from "@mui/material";
import {userInfo, userPermissions} from "../../containers/user/store/selectors";
import {clientsOrganizations} from "../../containers/clients/store/selectors";
import {getValue} from "../../utils/objects";
import connect from 'react-redux/es/connect/connect';

const stateToProps = state => ({
  userInfo: userInfo(state),
  userPermissions: userPermissions(state),
  clientsOrganizations: clientsOrganizations(state),
});

const PageLayout = ({
                      hasNavigation = true,
                      userInfo,
                      clientsOrganizations,
                      userPermissions,
                      ...props
}) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [clientId, setClientId] = useState(props.match?.params?.clientId || false);
  const [organizations, setOrganizations] = useState([]);
  const roles = getValue(userInfo, 'roles', []);

  useEffect(() => {
    setOrganizations(clientsOrganizations?.data?.data ?? []);
  }, [clientsOrganizations]);

  useEffect(() => {
    setClientId(props.match?.params?.clientId || false);
  }, [props.match?.params?.clientId]);

  const shouldHideNav = organizations.length === 0;

  return (
    <Box sx={{ bgcolor: 'background.dark', display: 'flex', minHeight: '100vh', width: '100%' }}>
      <TopBar clientId={props.match?.params?.clientId || false} onMobileNavOpen={() => setMobileNavOpen(true)}/>

      {hasNavigation && (
        <>
          <NavBar onMobileClose={() => setMobileNavOpen(false)}
                  openMobile={isMobileNavOpen}
                  clientId={clientId}
                  organizations={organizations}
                  shouldHideNav={shouldHideNav}
                  userPermissions={userPermissions ?? []}
                  roles={roles} />

          <Box sx={{ pl: { lg: shouldHideNav ? 0 : 72 }, width: 1 }}>
            <Box sx={{ px: 8, py: 16, overflowX: 'hidden', overflowY: 'auto' }}>
              {props.children}
            </Box>
          </Box>
        </>
      )}

      {!hasNavigation && (
        <>
          <Box sx={{ px: 8, p: 16, overflowX: 'hidden', overflowY: 'auto', width: 1 }}>
            {props.children}
          </Box>
        </>
      )}
    </Box>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node
};

export default connect(stateToProps, null)(PageLayout);
