import React, {useEffect, useState} from 'react';
import connect from 'react-redux/es/connect/connect';
import {Box, Breadcrumbs, Link} from '@mui/material';
import Page from '../../../components/Page';
import ClientAddForm from '../subcomponents/ClientAddForm';
import {useSnackbar} from 'notistack';

import {uiRedirect} from '../../../store/actions';
import {
  clientsLoadCreateOrganizations,
  clientsLoadResetOrganization
} from '../../clients/store/actions';
import {clientsMsg, clientsOrganization} from '../../clients/store/selectors';
import {Link as RouterLink} from "react-router-dom";

const stateToProps = state => ({
  clientsOrganization: clientsOrganization(state),
  clientsMsg: clientsMsg(state)
});

const actionsToProps = dispatch => ({
  clientsLoadCreateOrganizations: (payload) => dispatch(clientsLoadCreateOrganizations(payload)),
  clientsLoadResetOrganization: (payload) => dispatch(clientsLoadResetOrganization(payload)),
  uiRedirect: (url) => dispatch(uiRedirect(url)),
});

const ClientAdd = (props) => {
  const {enqueueSnackbar} = useSnackbar();
  const [clientsList, setClientsList] = useState([]);
  const [isSubmittingState, setIsSubmittingState] = useState(false);
  const [errorsServer, setErrorsServer] = useState([]);


  //Handle client add response
  useEffect(() => {
    if (props.clientsOrganization.data) {
      enqueueSnackbar('Client added', {
        variant: 'success',
        persist: true
      });
      props.uiRedirect({
        pathname: `/clients/${props.clientsOrganization.data.id}/settings`
      })
    } else if (props.clientsMsg) {
      enqueueSnackbar(props.clientsMsg.msg, {
        variant: 'error',
        persist: true
      });
      setIsSubmittingState(false);
      setErrorsServer(props.clientsMsg.errors);
    }
  }, [props.clientsOrganization])

  const breadCrumbs =
    <Breadcrumbs aria-label="breadcrumb">
      <Link variant="body1" color="inherit" to="/clients" component={RouterLink}>
        Clients
      </Link>

      <Link variant="body1" color="textPrimary" to="#" component={RouterLink}>
        Add client
      </Link>
    </Breadcrumbs>;

  return (
    <Page title="New client" breadCrumbs={breadCrumbs}>
      <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
        <ClientAddForm clientsList={clientsList}
                       clientsLoadCreateOrganizations={props.clientsLoadCreateOrganizations}
                       isSubmittingState={isSubmittingState}
                       setIsSubmittingState={setIsSubmittingState}
                       errorsServer={errorsServer}
                       setErrorsServer={setErrorsServer} />
      </Box>
    </Page>
  );
};

export default connect(stateToProps, actionsToProps)(ClientAdd);
