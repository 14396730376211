import React, {useEffect, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {useSnackbar} from 'notistack';
import {
  Avatar,
  Box,
  Card,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip, useTheme,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Edit as EditIcon, Search as SearchIcon, Trash as TrashIcon} from 'react-feather';
import getInitials from '../../../utils/getInitials';
import {clientsDeleteOrganization} from '../store/selectors';
import {clientsLoadDeleteOrganization, clientsLoadResetDeleteOrganization} from '../store/actions';
import connect from 'react-redux/es/connect/connect';
import {THEMES} from "../../../constants";

const sortOptions = [
  {
    value: 'updatedAt|desc',
    label: 'Last update (newest first)'
  },
  {
    value: 'updatedAt|asc',
    label: 'Last update (oldest first)'
  },
  {
    value: 'orders|desc',
    label: 'Total orders (high to low)'
  },
  {
    value: 'orders|asc',
    label: 'Total orders (low to high)'
  }
];

const applyFilters = (clients, query, filters) => {
  return clients.filter((client) => {
    let matches = true;

    if (query) {
      const properties = ['search_index'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (client[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && client[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });
};

const applyPagination = (clients, page, limit) => {
  return clients.slice(page * limit, page * limit + limit);
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const applySort = (clients, sort) => {
  const [orderBy, order] = sort.split('|');
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = clients.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

const useStyles = makeStyles((theme) => ({
  root: {},
  queryField: {
    width: 500
  },
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  }
}));

const stateToProps = state => ({
  clientsDeleteOrganization: clientsDeleteOrganization(state)
});

const actionsToProps = dispatch => ({
  clientsLoadDeleteOrganization: (payload) => dispatch(clientsLoadDeleteOrganization(payload)),
  clientsLoadResetDeleteOrganization: () => dispatch(clientsLoadResetDeleteOrganization())
});

const Results = ({
                   className,
                   clients,
                   organizations,
                   ...rest
                 }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState('all');
  const [selectedClients, setSelectedCustomers] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState(sortOptions[0].value);
  const [filters, setFilters] = useState({
    hasAcceptedMarketing: null,
    isProspect: null,
    isReturning: null
  });
  const {enqueueSnackbar} = useSnackbar();

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
    setPage(0);
  };

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
  };

  const handleSelectAllCustomers = (event) => {
    setSelectedCustomers(event.target.checked
      ? clients.map((client) => client.id)
      : []);
  };

  const handleSelectOneClient = (event, clientId) => {
    if (!selectedClients.includes(clientId)) {
      setSelectedCustomers((prevSelected) => [...prevSelected, clientId]);
    } else {
      setSelectedCustomers((prevSelected) => prevSelected.filter((id) => id !== clientId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const deleteClient = (client) => {
    if (window.confirm(`Are you sure you want to delete client "${client.name}"?`)) {
      rest.clientsLoadDeleteOrganization({tenantId: client.id});
    }
  };

  const filteredCustomers = applyFilters(clients, query, filters);
  const sortedCustomers = applySort(filteredCustomers, sort);
  const paginatedCustomers = applyPagination(sortedCustomers, page, limit);

  const canDeleteClient = (client) => {
    const filteredOrg = organizations.filter((org) => org.id === client.id);
    if( filteredOrg.length !== 1 ) {
      return false;
    }

    return filteredOrg[0].userPermissions.includes('tenant.delete');
  };

  useEffect(() => {
    if (rest.clientsDeleteOrganization.data == 'deleted') {
      enqueueSnackbar('Client has been deleted!', {
        variant: 'success',
        persist: true
      });

      rest.clientsLoadOrganizations();
    }
  }, [rest.clientsDeleteOrganization.data]);

  useEffect(() => {
    return () => rest.clientsLoadResetDeleteOrganization()
  }, [])

  return (
    <Card className={clsx(classes.root, className)}>
      <Box p={2} minHeight={56} display="flex" alignItems="center">
        <TextField className={classes.queryField}
                   InputProps={{
                     startAdornment: (
                       <InputAdornment position="start">
                         <SvgIcon fontSize="small" color="action">
                           <SearchIcon />
                         </SvgIcon>
                       </InputAdornment>
                     )}}
                   onChange={handleQueryChange}
                   placeholder="Search clients"
                   value={query}
                   variant="outlined"/>
        <Box flexGrow={1}/>
      </Box>

      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Uid</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedCustomers.map((client) => {
                return (
                  <TableRow hover key={client.id}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Avatar className={classes.avatar} sx={theme.name === THEMES.LIGHT ? { bgcolor: 'primary.main', color: 'text.white' } : { bgcolor: 'primary.main', color: 'text.fullBlack' }} src={client.avatar_url}>
                          {getInitials(client.name)}
                        </Avatar>
                        <div>
                          <Link color="inherit" component={RouterLink} to={`/clients/${client.id}/daily-dashboard`} variant="h6" sx={{ ml: 2 }}>
                            {client.name}
                          </Link>
                        </div>
                      </Box>
                    </TableCell>

                    <TableCell>{client.uid}</TableCell>

                    <TableCell align="right">
                      <Tooltip title="Edit client">
                        <IconButton component={RouterLink} to={`/clients/${client.id}/settings`}>
                          <SvgIcon fontSize="small">
                            <EditIcon/>
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>

                      {canDeleteClient(client) && (
                        <Tooltip title="Delete client">
                          <IconButton onClick={() => deleteClient(client)}>
                            <SvgIcon fontSize="small">
                              <TrashIcon/>
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination component="div"
                       count={filteredCustomers.length}
                       onPageChange={handlePageChange}
                       onRowsPerPageChange={handleLimitChange}
                       page={page}
                       rowsPerPage={limit}
                       rowsPerPageOptions={[5, 10, 25]} />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  clients: PropTypes.array.isRequired
};

Results.defaultProps = {
  clients: []
};

export default connect(stateToProps, actionsToProps)(Results)
