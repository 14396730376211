import React, {useEffect, useRef, useState,} from 'react';
import connect from 'react-redux/es/connect/connect';
import {getValue} from "AppUtils/objects";

import {
  Avatar,
  Box, Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Link,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  Badge, Chip,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import Page from '../../../components/Page';
import Users from '../subcomponents/Users';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {useSnackbar} from 'notistack';
import {
  clientsLoadOrganization,
  clientsLoadResetOrganization,
  clientsLoadResetUsers,
  clientsLoadUpdateOrganization,
  clientsLoadUsers
} from "../../clients/store/actions";
import {
  clientsOrganization,
  clientsUpdateOrganization,
  clientsUsers
} from "../../clients/store/selectors";
import LoadingScreen from "../../../components/LoadingScreen";
import {userInfo} from "../../user/store/selectors";
import {
  userLoadResetMsg,
} from "../../user/store/actions";
import {Link as RouterLink} from "react-router-dom";
import getInitials from "../../../utils/getInitials";
import {Edit, EditOutlined} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  cardName: {
    '&:last-child': {
      paddingBottom: 16
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    width: "80%",
    maxWidth: 400,
    outline: 0
  },
  titleData: {
    display: 'flex',
    alignItems: 'center',
  },
  inputLabel: {
    display: 'inline-block',
    marginLeft: 10,
    verticalAlign: 'middle'
  },
  titleIcon: {
    marginLeft: 8,
    verticalAlign: 'middle'
  },
  uploadFile: {},
  uploadFileLabel: {
    marginRight: 15
  },
  error: {
    display: 'block',
    fontSize: '0.75rem',
    marginTop: '3px',
    marginLeft: '14px',
    marginRight: '14px',
    color: '#f44336',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  }
}));

const stateToProps = state => ({
  clientsOrganization: clientsOrganization(state),
  clientsUpdateOrganization: clientsUpdateOrganization(state),
  clientsUsers: clientsUsers(state),
  userInfo: userInfo(state),
});

const actionsToProps = dispatch => ({
  clientsLoadOrganization: (payload) => dispatch(clientsLoadOrganization(payload)),
  clientsLoadResetOrganization: (payload) => dispatch(clientsLoadResetOrganization(payload)),
  clientsLoadUpdateOrganization: (payload) => dispatch(clientsLoadUpdateOrganization(payload)),
  clientsLoadUsers: (payload) => dispatch(clientsLoadUsers(payload)),
  clientsLoadResetUsers: (payload) => dispatch(clientsLoadResetUsers(payload)),
  userLoadResetMsg: (payload) => dispatch(userLoadResetMsg(payload)),
});

const ClientDetails = (props) => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const clientId = getValue(props, 'match.params.clientId');
  const [uploadedAvatar, setUploadedAvatar] = useState();
  const [uploadedAvatarPreview, setUploadedAvatarPreview] = useState();
  const [openModal, setOpenModal] = useState(false);

  const [clientDetailsEditMode, setClientDetailsEditMode] = useState(false);
  const [client, setClient] = useState(props.clientsOrganization);
  const [users, setUsers] = useState([])

  const getClients = () => {
    props.clientsLoadUsers({tenantId: clientId});
  };

  useEffect(() => {
    props.clientsLoadOrganization({id: clientId});

    getClients();

    return function cleanup() {
      props.userLoadResetMsg();
      props.clientsLoadResetUsers();
    }
  }, []);

  useEffect(() => {
    setClient(props.clientsOrganization);
  }, [false, props.clientsOrganization.data, props.clientsOrganization.data?.id]);

  useEffect(() => {
    setUsers(props.clientsUsers);
  }, [props.clientsUsers.data]);

  useEffect(() => {
    if (props.clientsUpdateOrganization.data && !props.clientsUpdateOrganization.data.isLoading) {
      props.clientsLoadOrganization({id: clientId});
      enqueueSnackbar('Client details saved', {
        variant: 'success',
        persist: true
      });
    }
  }, [props.clientsUpdateOrganization]);

  useEffect(() => {
    if (!uploadedAvatar) {
      setUploadedAvatar(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(uploadedAvatar);
    setUploadedAvatarPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [uploadedAvatar]);


  const selectNewAvatar = (event) => {
    if (!event.currentTarget.files || event.currentTarget.files.length === 0) {
      setUploadedAvatar(undefined);
      return;
    }

    setUploadedAvatar(event.currentTarget.files[0]);
  };

  if (!client.data) {
    return <LoadingScreen/>
  }

  const roles = getValue(props.userInfo, 'roles', []);

  const breadCrumbs =
    <Breadcrumbs aria-label="breadcrumb">
      <Link variant="body1" to="#" component={RouterLink}>
        Management
      </Link>

      {client.data.id && roles.length > 0 &&
      <Link variant="body1" color="inherit" to="#" component={RouterLink}>
        Settings
      </Link>}

      <Link variant="body1" color="textPrimary" to="#" component={RouterLink}>
        {client.data.name}
      </Link>
    </Breadcrumbs>;

  return (
    <Page breadCrumbs={breadCrumbs} title="Organization">
      <Grid container={true} spacing={3}>
        <Grid item md={12} xs={12}>
          <Card>
            <CardHeader title="Organization Name"/>

            <CardContent className={classes.cardName}>
              <Formik initialValues={{
                name: getValue(client, 'data.name', ''),
                filterAlias: getValue(client, 'data.filter_alias', '') ?? '',
                avatarUrl: getValue(client, 'data.avatar_url', ''),
                submit: null
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().min(5).max(255).required('Name is required'),
                filterAlias: Yup.string().min(5).max(255),
              })}
              onSubmit={(values, {
                resetForm,
                setErrors,
                setStatus,
                setSubmitting
              }) => {
                let organization = {
                  name: values.name,
                  filter_alias: values.filterAlias,
                };

                if( uploadedAvatar != null ) {
                  organization.avatar = uploadedAvatar;
                }

                props.clientsLoadUpdateOrganization({
                  id: client.data.id,
                  organization: organization,
                });

                setClientDetailsEditMode(false);
                setSubmitting(false);
              }}>
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                  <form onSubmit={handleSubmit}>
                    {clientDetailsEditMode ?
                      <>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box>
                            <TextField error={Boolean(touched.name && errors.name)}
                                       fullWidth
                                       helperText={touched.name && errors.name}
                                       label="Full name"
                                       name="name"
                                       onBlur={handleBlur}
                                       onChange={handleChange}
                                       required
                                       value={values.name}
                                       variant="outlined"
                                       sx={{ mb: 4 }} />

                            <TextField error={Boolean(touched.filterAlias && errors.filterAlias)}
                                       fullWidth
                                       helperText={touched.filterAlias && errors.filterAlias}
                                       label="Filter Alias"
                                       name="filterAlias"
                                       onBlur={handleBlur}
                                       onChange={handleChange}
                                       required
                                       value={values.filterAlias}
                                       variant="outlined" />
                          </Box>

                          <Box sx={{ display: 'flex', alignItems: 'center', ml: 6 }}>
                            <input accept="image/*" style={{ display: 'none' }} id="input_client_avatar"
                              onChange={(event) => selectNewAvatar(event)}
                              multiple type="file"
                            />

                            <label htmlFor="input_client_avatar">
                              <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                     badgeContent={
                                        <Box sx={{ borderRadius: '100%', backgroundColor: 'background.default', p: 0.5}}>
                                          <Edit sx={{
                                            width: 18,height: 18, backgroundColor: 'primary.lightest', borderRadius: '100%', color: 'primary.main', p: 0.5,
                                            '&:hover': {
                                              cursor: 'pointer', backgroundColor: 'primary.lighter'
                                            }
                                          }} />
                                        </Box>
                                      }>
                                <Avatar src={uploadedAvatarPreview ?? values.avatarUrl} variant="square">
                                  {getInitials(values.name)}
                                </Avatar>
                              </Badge>
                            </label>
                          </Box>
                        </Box>

                        <Box mt={4}>
                          <Button variant="contained" color="secondary" type="submit" disabled={isSubmitting}>
                            Save
                          </Button>
                        </Box>
                      </>
                      :
                      <>
                        <Grid container={true} spacing={3}>
                          <Grid item md={12} xs={12}>
                            <Box sx={{ width: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body1" color="textPrimary" sx={{ display: 'block', mr: 4 }}>
                                  {values.name} ({values.filterAlias ? `filter alias ${values.filterAlias}` : 'no filter alias'})
                                </Typography>

                                <Avatar src={client.data.avatar_url} variant="square" />
                              </Box>

                              <Tooltip title="Edit">
                                <IconButton onClick={() => setClientDetailsEditMode(true)}>
                                  <SvgIcon fontSize="small">
                                    <EditOutlined/>
                                  </SvgIcon>
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    }
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box mt={6}>
        <Users getClients={getClients} users={getValue(users, 'data.data', [])} clientId={clientId}/>
      </Box>
    </Page>
  );
};

export default connect(stateToProps, actionsToProps)(ClientDetails);
